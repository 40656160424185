import { useTranslation } from "react-i18next";
import {paintingsArray, digitalArray, fantasyArray} from "../image-data/image-data";

export enum GalleryType {
  PAINTINGS = "PAINTINGS",
  FANTASY = "FANTASY",
  DIGITAL = "DIGITAL",
}

const fantasyPath = "/fantasy/";
const paintingsPath = "/abstract/";
const digitalPath = "/digital/";

// https://github.com/i18next/react-i18next/issues/909

// create url based and return the same array
const renderUrlAndDescription = (
  images: { original: string; thumbnail: string; description: string }[],
  path: string
) => {
  const {t} = useTranslation();
  
  return(
    images.map((image) => ({
      original: `${path}${image.original}`,
      thumbnail: `${path}${image.thumbnail}`,
      description: t(`${image.description}`),
    })
    )
  )};

export const images = (galleryType: keyof typeof GalleryType) => {
  switch (galleryType) {
    case "PAINTINGS":
      return renderUrlAndDescription(paintingsArray, paintingsPath);
    
    case "DIGITAL":
      return renderUrlAndDescription(digitalArray, digitalPath)

    default:
      return renderUrlAndDescription(fantasyArray, fantasyPath);
  }
};
