import React, { useState } from "react";

import { Modal } from "../../components/modal/Modal";
import { Gallery } from "../../components/gallery/Gallery";
import { images } from "../../services/images";
import { Thumbnails } from "../../components/thumbnails/Thumbnails";
import { ThumbnailWrap } from "../../components/thumbnail-wrap/ThumbnailWrap";

export const Digital = () => {
  // check if modal is open
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);

  // keep track of which image was clicked
  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  return (
    <ThumbnailWrap>
      <Thumbnails
        galleryType="DIGITAL"
        onClick={() => setIsGalleryModalOpen(true)}
        updateImageIndex={setActiveIndex}
      />
      <Modal
        close={() => setIsGalleryModalOpen(false)}
        isOpen={isGalleryModalOpen}
      >
        <Gallery startIndex={activeIndex} items={images("DIGITAL")} />
      </Modal>
    </ThumbnailWrap>
  );
};
