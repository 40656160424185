import React from "react";
import classNames from "classnames";

import styles from "./home.module.scss";

export const Home = () => {
  return (
    <div className={classNames(styles.view, styles.home)}>
      <div className={styles.title}>Andrus Raag</div>
    </div>
  );
};
