import React, { FC, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Home } from "./views/home/Home";
import { Bio } from "./views/bio/Bio";
import { Fantasy } from "./views/fantasy/Fantasy";
import { Paintings } from "./views/paintings/Paintings";
import { Test } from "./views/test/Test";
import { Digital } from "./views/digital/Digital";
import { TailwindTest } from "./views/tailwind-test/TailwindTest";

export interface MainProps {
  url: (location: string) => void;
}

export const Main: FC<MainProps> = ({ url }) => {
  // check url name
  const location = useLocation();

  // change url input value with navigation
  useEffect(() => {
    url(location.pathname);
  }, [url, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/paintings" element={<Paintings />} />
      <Route path="/fantasy" element={<Fantasy />} />
      <Route path="/bio" element={<Bio />} />
      <Route path="/digital" element={<Digital />} />
      <Route path="/test" element={<Test />} />
      <Route path="/tailwind-test" element={<TailwindTest />} />
    </Routes>
  );
};
