import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

export type DefinedWindowDimensions = {
  isMobile: boolean;
  isDesktop: boolean;
};

const WindowDimensionsContext = createContext<DefinedWindowDimensions>({
  isMobile: false,
  isDesktop: false,
});

function getWindowDimensions() {
  return {
    isMobile: window.innerWidth < 500,
    isDesktop: window.innerWidth >= 500,
  };
}

export const WindowDimensions: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowDimensionsContext.Provider value={windowDimensions}>
      {children}
    </WindowDimensionsContext.Provider>
  );
};

export const useWindowDimensions = () =>
  useContext<DefinedWindowDimensions>(WindowDimensionsContext);
