// create 300x300 thumbs here: http://makethumbnails.com/#dropzone

export const digitalArray = [
  {
    key: "Harvest.jpg",
    original: "Harvest season.jpg",
    thumbnail: "Harvest season--thumb.jpg",
    description: "Lõikuskuu",
  },
  {
    key: "Patrull.jpg",
    original: "Patrull.jpg",
    thumbnail: "Patrull--thumb.jpg",
    description: "Patrull",
  },
  {
    key: "Metsamaal.jpg",
    original: "Metsamaal.jpg",
    thumbnail: "Metsamaal--thumb.jpg",
    description: "Metsamaal",
  },
  {
    key: "Ikarus.jpg",
    original: "Ikarus.jpg",
    thumbnail: "Ikarus--thumb.jpg",
    description: "Ikarus",
  },
]

export const paintingsArray = [
  // {
  //   key: "Nad tõstavad pead.jpg",
  //   original: "Nad tõstavad pead.jpg",
  //   thumbnail: "Nad tõstavad pead--thumb.jpg",
  //   description: "Nad tõstavad pead",
  // },
  {
    key: "Seal keset seda suurt tühjust.jpg",
    original: "Seal keset seda suurt tühjust.jpg",
    thumbnail: "Seal keset seda suurt tühjust--thumb.jpg",
    description: "Seal keset seda suurt tühjust",
  },
  {
    key: "ancient.jpg",
    original: "ancient.jpg",
    thumbnail: "ancient--thumb.jpg",
    description: "Muinaslõke",
  },
  {
    key: "celebrating.jpg",
    original: "celebrating.jpg",
    thumbnail: "celebrating--thumb.jpg",
    description: "Rõõm postmodernismi lõpust",
  },
  {
    key: "Demiurg.jpg",
    original: "Demiurg.jpg",
    thumbnail: "Demiurg--thumb.jpg",
    description: "Demiurg",
  },
  {
    key: "Eesti.jpg",
    original: "Eesti maa.jpg",
    thumbnail: "Eesti maa--thumb.jpg",
    description: "Eesti maa",
  },
  {
    key: "Kivist.jpg",
    original: "Kivist ring.jpg",
    thumbnail: "Kivist ring--thumb.jpg",
    description: "Kivist ring",
  },
  {
    key: "Haldja.jpg",
    original: "Haldja mets.jpg",
    thumbnail: "Haldja mets--thumb.jpg",
    description: "Haldjamets",
  },
  {
    key: "Jälg.jpg",
    original: "Jälg seinal.jpg",
    thumbnail: "Jälg seinal--thumb.jpg",
    description: "Jälg seinal",
  },
  {
    key: "Kannataja.jpg",
    original: "Kannataja.jpg",
    thumbnail: "Kannataja--thumb.jpg",
    description: "Kannataja",
  },
  {
    key: "Karst.jpg",
    original: "Karst.jpg",
    thumbnail: "Karst--thumb.jpg",
    description: "Karst",
  },
  {
    key: "Kulukad.jpg",
    original: "Kulukad.jpg",
    thumbnail: "Kulukad--thumb.jpg",
    description: "Kulukad",
  },
  {
    key: "Laukad.jpg",
    original: "Laukad.jpg",
    thumbnail: "Laukad--thumb.jpg",
    description: "Laukad",
  },
  {
    key: "Madalikud.jpg",
    original: "Madalikud.jpg",
    thumbnail: "Madalikud--thumb.jpg",
    description: "Madalikud",
  },
  {
    key: "matrjoškatehas.jpg",
    original: "matrjoškatehas.jpg",
    thumbnail: "matrjoškatehas--thumb.jpg",
    description: "Matrjoškatehas",
  },
  // {
  //   key: "nameless2.jpg",
  //   original: "nameless2.jpg",
  //   thumbnail: "nameless2--thumb.jpg",
  //   description: "Nimetu 2",
  // },
  {
    key: "Niit.jpg",
    original: "Niit.jpg",
    thumbnail: "Niit--thumb.jpg",
    description: "Niit",
  },
  // {
  //   key: "nova_prospekt.jpg",
  //   original: "nova_prospekt.jpg",
  //   thumbnail: "nova_prospekt--thumb.jpg",
  //   description: "Nova Prospekt",
  // },
  {
    key: "ruuge.jpg",
    original: "ruuge.jpg",
    thumbnail: "ruuge--thumb.jpg",
    description: "Ruuge",
  },
  {
    key: "spanish_motif.jpg",
    original: "spanish_motif.jpg",
    thumbnail: "spanish_motif--thumb.jpg",
    description: "Hispaania motiiv",
  },
  {
    key: "Spekter.jpg",
    original: "Spekter.jpg",
    thumbnail: "Spekter--thumb.jpg",
    description: "Spekter",
  },
  {
    key: "Emajõe kallastel.jpg",
    original: "Emajõe kallastel.jpg",
    thumbnail: "Emajõe kallastel--thumb.jpg",
    description: "Emajõe kallastel",
  },
  {
    key: "Sõiduk.jpg",
    original: "Sõiduk.jpg",
    thumbnail: "Sõiduk--thumb.jpg",
    description: "Sõiduk",
  },
  {
    key: "tree.jpg",
    original: "tree.jpg",
    thumbnail: "tree--thumb.jpg",
    description: "Puu",
  },
  {
    key: "Unenägu.jpg",
    original: "Unenägu.jpg",
    thumbnail: "Unenägu--thumb.jpg",
    description: "Unenägu",
  },
  {
    key: "Vesipüks.jpg",
    original: "Vesipüks.jpg",
    thumbnail: "Vesipüks--thumb.jpg",
    description: "Vesipüks",
  },
  {
    key: "fountain.jpg",
    original: "fountain.jpg",
    thumbnail: "fountain--thumb.jpg",
    description: "Fontään",
  },
  {
    key: "green_heart.jpg",
    original: "green_heart.jpg",
    thumbnail: "green_heart--thumb.jpg",
    description: "Roheline süda",
  },
  {
    key: "white_dream.jpg",
    original: "white_dream.jpg",
    thumbnail: "white_dream--thumb.jpg",
    description: "Valge unenägu",
  },
  {
    key: "escape1.jpg",
    original: "escape1.jpg",
    thumbnail: "escape1--thumb.jpg",
    description: "Põgenemine varisevast koopast 1",
  },
  {
    key: "escape2.jpg",
    original: "escape2.jpg",
    thumbnail: "escape2--thumb.jpg",
    description: "Põgenemine varisevast koopast 2",
  },
  {
    key: "escape3.jpg",
    original: "escape3.jpg",
    thumbnail: "escape3--thumb.jpg",
    description: "Põgenemine varisevast koopast 3",
  },
  {
    key: "escape4.jpg",
    original: "escape4.jpg",
    thumbnail: "escape4--thumb.jpg",
    description: "Põgenemine varisevast koopast 4",
  },
  {
    key: "Katusealune.jpg",
    original: "Katusealune.jpg",
    thumbnail: "Katusealune--thumb.jpg",
    description: "Katusealune",
  },
  {
    key: "Tundmatu.jpg",
    original: "Tundmatu.jpg",
    thumbnail: "Tundmatu--thumb.jpg",
    description: "Tundmatu",
  },
  {
    key: "Viljakas pinnas.jpg",
    original: "Viljakas pinnas.jpg",
    thumbnail: "Viljakas pinnas--thumb.jpg",
    description: "Viljakas pinnas",
  },
];

export const fantasyArray = [
  {
    key: "Aastaajad.jpg",
    original: "Aastaajad.jpg",
    thumbnail: "Aastaajad--thumb.jpg",
    description: "Aastaajad",
  },
  {
    original: "Draakoni tulek.jpg",
    thumbnail: "Draakoni tulek--thumb.jpg",
    description: "Draakoni tulek",
  },
  {
    original: "fisherman.jpg",
    thumbnail: "fisherman--thumb.jpg",
    description: "Kalamees",
  },
  {
    original: "ford.jpg",
    thumbnail: "ford--thumb.jpg",
    description: "Koolmekoht",
  },
  {
    original: "forest.jpg",
    thumbnail: "forest--thumb.jpg",
    description: "Mets",
  },
  {
    original: "islandia.jpg",
    thumbnail: "islandia--thumb.jpg",
    description: "Islandia",
  },
  {
    original: "Havilootsik.jpg",
    thumbnail: "Havilootsik--thumb.jpg",
    description: "Havilootsik",
  },
  {
    original: "home2.jpg",
    thumbnail: "home2--thumb.jpg",
    description: "Kodu 2",
  },
  {
    original: "huldra.jpg",
    thumbnail: "huldra--thumb.jpg",
    description: "Huldra",
  },
  {
    original: "icallandtheycome.jpg",
    thumbnail: "icallandtheycome--thumb.jpg",
    description: "Ma kutsun ja nad tulevad",
  },
  {
    original: "Kolm trolli 2.jpg",
    thumbnail: "Kolm trolli 2--thumb.jpg",
    description: "Kolm trolli 2",
  },
  {
    original: "lumeeit2.jpg",
    thumbnail: "lumeeit2--thumb.jpg",
    description: "Lumeeit 2",
  },
  {
    original: "lumeeit3.jpg",
    thumbnail: "lumeeit3--thumb.jpg",
    description: "Lumeeit 3",
  },
  {
    original: "monge.jpg",
    thumbnail: "monge--thumb.jpg",
    description: "Monge",
  },
  {
    original: "mountains.jpg",
    thumbnail: "mountains--thumb.jpg",
    description: "Lennuk",
  },
  {
    original: "Morsja.jpg",
    thumbnail: "Morsja--thumb.jpg",
    description: "Mõrsja",
  },
  {
    original: "Neidis.jpg",
    thumbnail: "Neidis--thumb.jpg",
    description: "Neidis",
  },
  {
    original: "Ootamatu kohtumine II.jpg",
    thumbnail: "Ootamatu kohtumine II--thumb.jpg",
    description: "Ootamatu kohtumine II",
  },
  {
    original: "Ootamatu kohtumine.jpg",
    thumbnail: "Ootamatu kohtumine--thumb.jpg",
    description: "Ootamatu kohtumine",
  },
  {
    original: "Rippsild.jpg",
    thumbnail: "Rippsild--thumb.jpg",
    description: "Rippsild",
  },
  {
    original: "rotipyydja.jpg",
    thumbnail: "rotipyydja--thumb.jpg",
    description: "Rotipüüdja",
  },
  {
    original: "troll.jpg",
    thumbnail: "troll--thumb.jpg",
    description: "Troll",
  },
  {
    original: "Valgus laugaste kohal.jpg",
    thumbnail: "Valgus laugaste kohal--thumb.jpg",
    description: "Valgus laugaste kohal",
  },
  {
    original: "Salander.jpg",
    thumbnail: "Salander--thumb.jpg",
    description: "Salander",
  },
  {
    original: "Kännuvanake.jpg",
    thumbnail: "Kännuvanake--thumb.jpg",
    description: "Kännuvanake",
  },
  {
    original: "Ootamatu kohtumine III.jpg",
    thumbnail: "Ootamatu kohtumine III--thumb.jpg",
    description: "Ootamatu kohtumine III",
  },
];