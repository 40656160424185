import React, { FC, useRef, useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useGetLocales } from "../../hooks/useGetLocales";
import { useWindowDimensions } from "../window-dimensions/WindowDimensions";
import useOnClickOutside from "use-onclickoutside";

import styles from "./menu.module.scss";

interface MenuProps {
  className?: string;
  openClassName?: string;
}

export const Menu: FC<MenuProps> = ({ className, openClassName }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsMenuOpen(false));

  const { t } = useTranslation();
  const { setLocale } = useGetLocales();

  const { isMobile } = useWindowDimensions();

  const links = [
    {
      to: "/",
      label: t("Kodo"),
    },
    {
      to: "/fantasy",
      label: t("Fantaseerin"),
    },
    {
      to: "/digital",
      label: t("Digikunst"),
    },
    {
      to: "/paintings",
      label: t("Maalin"),
    },
    {
      to: "/bio",
      label: t("Maq"),
    },
    // {
    //   to: "/test",
    //   label: "Test",
    // },
  ];

  return (
    <div
      ref={ref}
      className={classNames(
        styles.wrap,
        {
          [styles["wrap--menu-closed"]]: !isMenuOpen,
        },
        isMenuOpen && openClassName && openClassName,
        className
      )}
    >
      <button
        className={styles.burger}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        |||
      </button>
      <div
        className={classNames(styles["nav-wrap"], {
          [styles["nav-wrap--closed"]]: !isMenuOpen,
        })}
      >
        {links.map((link, index) => (
          <NavItem
            to={link.to}
            key={index}
            onClick={() => setIsMenuOpen(false)}
          >
            {link.label}
          </NavItem>
        ))}
        <div className={styles["language-wrap"]}>
          <button
            className={styles.language}
            onClick={async () => {
              await setLocale("et");
            }}
          >
            {isMobile ? "EE" : "Eesti"}
          </button>
          /
          <button
            className={styles.language}
            onClick={async () => {
              await setLocale("en");
            }}
          >
            {isMobile ? "EN" : "English"}
          </button>
        </div>
      </div>
    </div>
  );
};

const NavItem: FC<NavLinkProps> = ({ children, ...props }) => (
  <NavLink
    to={props.to}
    className={({ isActive }) =>
      classNames(
        styles.link,
        { [styles["link--active"]]: isActive },
        props.className
      )
    }
    onClick={props.onClick}
  >
    {children}
  </NavLink>
);
