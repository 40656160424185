import React, { FC, useEffect, useState } from "react";

import { GalleryType, images } from "../../services/images";

import styles from "./thumbnails.module.scss";

export interface ThumbnailsProps {
  onClick(): void;
  updateImageIndex: (imageIndex: number) => void;
  galleryType: keyof typeof GalleryType;
}

export const Thumbnails: FC<ThumbnailsProps> = ({
  onClick,
  updateImageIndex,
  galleryType,
}) => {
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    updateImageIndex(imageIndex);
  }, [updateImageIndex, imageIndex]);

  return (
    <div className={styles["image-wrap"]}>
      {images(galleryType).map((image, index) => (
        <img
          className={styles.image}
          key={index}
          src={image.thumbnail}
          alt={image.original}
          onClick={() => (onClick(), setImageIndex(index))}
        />
      ))}
    </div>
  );
};
