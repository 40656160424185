import React, { FC, ReactNode, useEffect, useRef } from "react";
import styles from "./modal.module.scss";
import useOnClickOutside from "use-onclickoutside";
import { createPortal } from "react-dom";

export interface ModalProps {
  close(): void;
  isOpen: boolean;
  children?: ReactNode;
}

export const Modal: FC<ModalProps> = ({ children, close, isOpen }) => {
  const ref = useRef(null);

  useOnClickOutside(ref, close);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal--open");
    } else {
      document.body.classList.remove("modal--open");
    }
  });

  return isOpen
    ? createPortal(
        <div className={styles.overlay}>
          <div className={styles.modal} ref={ref}>
            {children}
          </div>
        </div>,
        document.body
      )
    : null;
};
