import React from "react";
import { useTranslation } from "react-i18next";

import Portrait from "../../images/portrait.jpg";

import styles from "./bio.module.scss";

export const Bio = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.view}>
      <img className={styles.portrait} src={Portrait} alt="artist" />
      <p className={styles.text}>
        {t(
          "Olen Tartu maalikunstnik ja illustraator. Maalimisega tegin algust 90ndate lõpul, ajendatuna fantaasiakunstist, heavy metalist, folkloorist ja õuduskirjandusest."
        )}
      </p>
      <p className={styles.text}>
        {t(
          "Maal on pilguheit kunstniku sisemaailma. See jutustab teistsugusest, salapärasest, tundmatust."
        )}
      </p>
      <p className={styles.text}>
        {t(
          "2008 omandasin magistrikraadi Tartu Ülikooli maalikunsti erialal, peale mida olen töötanud vabakutselise maalikunstnikuna ning osalenud hulgal isik- ja grupinäitustel Eestis ja võõrsil."
        )}
      </p>
      <p className={styles.text}>
        {t(
          "Samast ajast saadik olen ka Eesti Maalikunstnike Liidu ja alates 2009 aastast Eesti Kunstnike Liidu liige."
        )}
      </p>
      <p className={styles.text}>
        {t(
          "2012 aastal tärganud huvi digikunsti meediumi vastu on pakkunud väljundi töös arvutimängude, digimaalide ja graafilise disainiga."
        )}
      </p>
      <p className={styles.contact}>
        e-mail:
        <br />
        <span className={styles.email}>andrus.raag@gmail.com</span>
      </p>
    </div>
  );
};
