import cookie from "react-cookies";

import i18n from "../services/i18n";

const availableLocales = i18n.options.supportedLngs || [];

export function useGetLocales() {
  return {
    locales: availableLocales.map(locale => ({name: locale, code: locale})),
    activeLocale: {name: i18n.language, code: i18n.language},
    setLocale: async (localeCode : string) => {
      await i18n.changeLanguage(localeCode);  
      cookie.save("locale", localeCode, {path: "/"});
    }
  }
}