import React, { FC } from "react";
import { useTranslation } from "react-i18next";
// import classNames from 'classnames';

import styles from "./test.module.scss";

export const Test: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      {/* <svg
        className={styles.ex5}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 105.49 215.94"
      >
        <path
          className={styles["test-3"]}
          fill="#1d1d1b"
          d="M105.49,5.3S11.88-20.23,5.49,40.76-5.85,162,15.42,215.94H26.06s-36.17-75.89-8.51-170.21c25.53-22,37.59-31.92,84.4-28.37C105.49,9.56,105.49,5.3,105.49,5.3Z"
        />
      </svg> */}
      <div>Test switchable account permissions</div>

      <svg className={styles.ex5}>
        <rect className={styles["test-3"]} width="100" height="200" />
      </svg>
      <svg
        className={styles.ex5}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1 200.71"
      >
        <path
          className={styles["test-3"]}
          // fill="red"
          // stroke="black"
          strokeMiterlimit={10}
          d="M.5,0V200.71"
        />
      </svg>
    </div>
  );
};
