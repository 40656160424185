// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test_ex5__77zBm {
  margin: 100px;
  width: 200px;
  height: 200px;
  outline: 1px solid;
}

.test_test-3__P1m5d {
  fill: none;
  stroke: blue;
  stroke-width: 1px;
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 100ms linear;
}

.test_ex5__77zBm:hover .test_test-3__P1m5d {
  stroke-dashoffset: 200;
}`, "",{"version":3,"sources":["webpack://./src/views/test/test.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AADF;;AAIA;EACE,UAAA;EACA,YAAA;EACA,iBAAA;EAEA,qBAdW;EAeX,oBAAA;EACA,0CAAA;AAFF;;AAKA;EACE,sBApBW;AAkBb","sourcesContent":["$dashOffset: 200; // for testing svg animations\r\n\r\n.ex5 {\r\n  margin: 100px;\r\n  width: 200px;\r\n  height: 200px;\r\n  outline: 1px solid;\r\n}\r\n\r\n.test-3 {\r\n  fill: none;\r\n  stroke: blue;\r\n  stroke-width: 1px;\r\n\r\n  stroke-dasharray: $dashOffset;\r\n  stroke-dashoffset: 0;\r\n  transition: stroke-dashoffset 100ms linear;\r\n}\r\n\r\n.ex5:hover .test-3 {\r\n  stroke-dashoffset: $dashOffset;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ex5": `test_ex5__77zBm`,
	"test-3": `test_test-3__P1m5d`
};
export default ___CSS_LOADER_EXPORT___;
