import React, { FC } from "react";
import classNames from "classnames";
import ReactImageGallery, { ReactImageGalleryProps } from "react-image-gallery";

import styles from "./gallery.module.scss";

export interface GalleryNavProps
  extends Pick<ReactImageGalleryProps, "onClick"> {
  left?: boolean;
}

export const Gallery: FC<ReactImageGalleryProps> = ({ ...props }) => {
  return (
    <ReactImageGallery
      additionalClass={styles.gallery}
      items={props.items}
      startIndex={props.startIndex}
      showFullscreenButton={false}
      slideDuration={200}
      renderPlayPauseButton={() => null}
      renderLeftNav={GalleryLeftNav}
      renderRightNav={GalleryRightNav}
    />
  );

  function GalleryLeftNav(onClick: any) {
    return <GalleryNav onClick={onClick} />;
  }

  function GalleryRightNav(onClick: any) {
    return <GalleryNav left={false} onClick={onClick} />;
  }
};

const GalleryNav: React.FC<GalleryNavProps> = ({ left = true, ...props }) => (
  <div
    className={classNames(styles["nav-wrap"], {
      [styles["nav-wrap--left"]]: left,
      [styles["nav-wrap--right"]]: !left,
    })}
    onClick={props.onClick}
  >
    <svg
      className={classNames(styles.nav, styles["nav--top"], {
        [styles["nav--left"]]: left,
        [styles["nav--right"]]: !left,
      })}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1 50"
    >
      <path className={styles.path} strokeMiterlimit={10} d="M.5,0V200.71" />
    </svg>

    <svg
      className={classNames(styles.nav, styles["nav--bottom"], {
        [styles["nav--left"]]: left,
        [styles["nav--right"]]: !left,
      })}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1 50"
    >
      <path className={styles.path} strokeMiterlimit={10} d="M.5,0V200.71" />
    </svg>
  </div>
);
