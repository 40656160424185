import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import { CodeBlock, dracula, monoBlue } from "react-code-blocks";
import ImgTailwindCode from "./tailwind-code.jpg";
import ImgScssCode from "./scss-code.jpg";
import LogoTailwind from "./Tailwind.png";
import LogoSass from "./Sass.png";

import styles from "./tailwind-test.module.scss";

export const TailwindTest: FC = () => {
  return (
    <>
      <div className={styles.head}>
        <SectionWrap>
          <img className={styles.logo} src={LogoTailwind} />
          <img className={styles.logo} src={LogoSass} />
        </SectionWrap>
      </div>

      <View>
        <h2 className={styles.h2}>Süntaks</h2>

        <SectionWrap>
          <Block
            kind={{
              tsx: `<div className="w-72" />  /* sarnane eel-defineeritud värvidega black, white, red jne. */`,
            }}
          />

          <div className="relative w-72 bg-[#506280] text-white p-4 text-center">
            width: 72 * 4 (288px)
            <Arrow className={styles["arrow-right"]} />
          </div>

          <Block
            kind={{
              tsx: `<div className="w-[300px] />`,
            }}
          />

          <div className="relative w-[300px] bg-[#506280] text-white p-4 text-center">
            width: 300px
            <Arrow className={styles["arrow-right"]} />
          </div>

          <Block
            kind={{
              tsx: `<div className="w-[custom] />`,
              js: `module.exports = {
  content: ["./src/**/*.{html,tsx}"],
  theme: {
    extend: {
      width: {
        custom: "300px",
      },
    },
  },
};`,
            }}
          />

          <div className="relative w-custom bg-[#506280] text-white p-4 text-center">
            width: custom (300px)
            <Arrow className={styles["arrow-right"]} />
          </div>

          <Block
            kind={{
              tsx: `<div className="after:[width:300px]" />`,
            }}
            features={[
              "Pole kindel kui suur osa CSS'ist üldse on kaetud",
              "Eel-defineeritud klasside hulk on piiratud",
            ]}
          />

          <div className="relative after:content-['300px_with_:after'] after:block after:p-4 after:bg-[#506280] after:[width:300px] after:-z-10 after:text-white after:text-center">
            <Arrow className={styles["arrow-right"]} />
          </div>
        </SectionWrap>

        <h2 className={styles.h2}>Reeglite nimed</h2>

        <SectionWrap>
          <Block
            kind={{
              css: `margin: --> m-[0, 4, ...]
padding-right: --> pr-[0, 4, ...]
justify-content: --> justify-[start, end, ...]
tailwindi justify-center: justify-content, justify-items või justify-self?
align-items: --> items-[start, end, ...]
color: --> text-[red, #000, ...]
font-size: --> text-[xs, 2x1, ...]
container: --> max-width: 100%
hidden: --> display: none
flex: --> display: flex (mitte flex: 1;)
line-height: leading-[tight, snug, ...]
          `,
            }}
            features={[
              "Võime kirjutada <i>shorthand</i> reegleid nagu <i>background</i>, <i>flex: 1;</i> jne. puudub",
              "Puudub reeglipärasus asjade nimetamisel",
              "Puudub mõtteline <i>reegel-väärtus</i> seos (background: red --> punane taust).",
              "Süntaktiline lisakiht, millega töötada: <i>väärtus-->css-->tailwind</i>",
            ]}
          />
        </SectionWrap>
      </View>

      <View>
        <h2 className={styles.h2}>
          Harjutus: noole värvi muutmine nupu peal hoverdades
        </h2>

        <SectionWrap>
          <Block
            kind={{
              tsx: `<button className="group/button flex justify-center items-center gap-[1ch] w-[300px] p-2 text-buttonTextPrimary bg-[#506280] transition-all duration-200 ease-in-out hover:bg-[#9dabc2] hover:text-buttonTextHover">
        <Arrow
          className={
            "w-5 h-5 fill-buttonTextPrimary group-hover/button:fill-buttonTextHover"
          }
        />
     Hover me
  </button>`,
              js: `/** @type {import('tailwindcss').Config} */
  module.exports = {
      content: ["./src/**/*.{html,tsx}"],
      theme: {
          extend: {
            colors: {
                buttonTextPrimary: "#ebebeb",
                buttonTextHover: "#162f57",
            },
          },
      },
  };
            `,
            }}
            features={[
              `button kasutab klassi <i>group/button</i>`,
              `nool saab klassile ligi <i>group-hover/button</i> abil`,
              `<i>Stylesheeti</i> asemel tuleb kasutada <i>tailwind.config</i> faili`,
            ]}
          >
            <button className="group/button flex justify-center items-center gap-[1ch] w-[300px] p-2 text-buttonTextPrimary bg-[#506280] transition-all duration-200 ease-in-out hover:bg-[#9dabc2] hover:text-buttonTextHover">
              <Arrow
                className={
                  "w-5 h-5 fill-buttonTextPrimary group-hover/button:fill-buttonTextHover"
                }
              />
              Hover me
            </button>
          </Block>

          <Block
            kind={{
              tsx: `<button className={styles["hover-button"]}>
     <Arrow className={styles.arrow} />
     Hover me
  </button>`,
              css: `
              $textPrimary: #ebebeb;
              $textHover: #162f57;
  
              .hover-button {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1ch;
                width: 300px;
                padding: 8px;
                color: $textPrimary;
                background: #506280;
                transition: background 0.2s ease-in-out;
  
                &:hover {
                  color: $textHover;
                  background: #9dabc2;
  
                  .arrow {
                    fill: $textHover;
                  }
                }
              }
  
              .arrow {
                width: 20px;
                height: 20px;
                fill: $textPrimary;
              }
            `,
            }}
          >
            <button className={styles["hover-button"]}>
              <Arrow className={styles.arrow} />
              Hover me
            </button>
          </Block>
        </SectionWrap>
      </View>

      <View>
        <h2 className={styles.h2}>
          Harjutus: jagatud väärtuste kasutamine, töötamine pseudoelementidega
        </h2>

        <SectionWrap>
          <Block
            kind={{
              tsx: `<div className="relative w-full h-[500px] outline outline-1 outline-[#808080]">
    <div className="flex flex-col justify-center h-full mr-heroImageWidth after:content[''] after:absolute after:top-0 after:right-0 after:w-heroImageWidth after:h-full after:bg-heroImageUrl after:bg-no-repeat after:bg-center after:bg-cover after:[mask-image:linear-gradient(270deg,rgba(0,0,0,1),rgba(0,0,0,0))]">
            <h3>Title</h3>
            <p>Some paragraph</p>
    </div>
</div>`,
              js: `/** @type {import('tailwindcss').Config} */
const heroSection = {
  heroImageWidth: "50%" /* calculate image-content relation */,
  heroImageUrl: "url('https://picsum.photos/id/237/200/300')",
};

module.exports = {
  content: ["./src/**/*.{html,tsx}"],
  theme: {
    extend:
      backgroundImage: {
        heroImageUrl: heroSection.heroImageUrl,
      },
      margin: {
        heroImageWidth: heroSection.heroImageWidth,
      },
      width: {
        heroImageWidth: heroSection.heroImageWidth,
      },
    },
  },
};`,
            }}
            features={[
              "Lokaalse tähtusega muutujaid defineerida ei saa",
              "Oht, et keegi kasutab globaalsel tasandil",
              "Selgitavad kommentaarid on eemal kohtadest kus neid kasutatakse",
              "Enne kasutamist tuleb muutujad lisada konfiguratsiooni",
              "Ei suutnud leida ühtegi süntaksit, mis lubaks kasutada väliseid URL'e otse",
              "tugi vähem kasutatud reeglitele nagu <i>mask-image</i> halb.",
              "Pseudo klasside kirjutamine vaevaline",
              "Reeglite sisse-välja kommenteerimine võimatu",
            ]}
          >
            <div className="relative w-full h-[500px] outline outline-1 outline-[#808080]">
              <div className="flex flex-col justify-center h-full mr-heroImageWidth after:content[''] after:absolute after:top-0 after:right-0 after:w-heroImageWidth after:h-full after:bg-heroImageUrl after:bg-no-repeat after:bg-center after:bg-cover after:[mask-image:linear-gradient(270deg,rgba(0,0,0,1),rgba(0,0,0,0))]">
                <h3 className={styles.h3}>Title</h3>
                <p className={styles.p}>Some paragraph</p>
              </div>
            </div>
          </Block>

          <Block
            kind={{
              tsx: `<div className={"styles.hero"}>
    <div className={styles["hero-content"]}>
        <h3>Title</h3>
        <p>Some paragraph</p>
    </div>
</div>`,
              css: `/* hero.module.scss */
$heroImageWidth: 50%;
            
.hero {
  position: relative;
  width: 100%;
  height: 500px;
  outline: 1px solid gray;
  
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: $heroImageWidth;
    height: 100%;
    /* background: blue; */ 
    background: url("https://picsum.photos/id/237/200/300") no-repeat right center /  cover;
    mask-image: linear-gradient(270deg, rgba(0,0,0,1), rgba(0,0,0,0));
  }
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-right: $heroImageWidth; /* reserve space for image */
}`,
            }}
          >
            <div className={styles.hero}>
              <div className={styles["hero-content"]}>
                <h3 className={styles.h3}>Title</h3>
                <p className={styles.p}>Some paragraph</p>
              </div>
            </div>
          </Block>
        </SectionWrap>
      </View>

      <View>
        <h2 className={styles.h2}>Harjutus: media query</h2>

        <SectionWrap>
          <Block
            kind={{
              tsx: `<div className="w-[100px] h-[100px] rounded-full transition-all duration-[400ms] ease-in-out bg-[#87ceeb] md:w-[200px] md:rounded-[30px] md:bg-[#5f9ea0] lg:w-[300px] lg:rounded-[10px] lg:bg-[#6495ed]" />`,
              js: `module.exports = {
  content: ["./src/**/*.{html,tsx}"],
  theme: {
    extend: {
      screens: {
        md: "800px",
        lg: "1000px",
      },
    },
  },
};`,
            }}
            features={["Vaatepõhiseid inline query'sid kirjutada ei saa"]}
          >
            <div className="w-[100px] h-[100px] rounded-full transition-all duration-[400ms] ease-in-out bg-[#87ceeb] md:w-[200px] md:rounded-[30px] md:bg-[#5f9ea0] lg:w-[300px] lg:rounded-[10px] lg:bg-[#6495ed]" />
          </Block>

          <Block
            kind={{
              tsx: `<div className={styles["media-box"]} />`,
              css: `.media-box {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: skyblue;
    transition: all .4s ease-in-out;
    
    @media only screen and (min-width: 1000px) {
        width: 200px;
        border-radius: 30px;
        background: cadetblue;
    }
    
    @media only screen and (min-width: 1800px) {
        width: 300px;
        border-radius: 10px;
        background: cornflowerblue;
    }
}`,
            }}
          >
            <div className={styles["media-box"]} />
          </Block>
        </SectionWrap>
      </View>

      <View>
        <h2 className={styles.h2}>Debugimine</h2>

        <SectionWrap>
          <div>
            <img src={ImgTailwindCode} />
            <List>
              <li>Keeruline debugida</li>
              <li>Developer Tools ei ole abiks vea otsingul</li>
            </List>
          </div>

          <div>
            <img src={ImgScssCode} />
            <List>
              <li>CSS Modules abil kompileeritud klassid</li>
              <li>Vaate/komponendi ülesleidmine lihtne</li>
            </List>
          </div>
        </SectionWrap>
      </View>

      <View>
        <h2 className={styles.h2}>SCSS funktsioonid, Calc()</h2>

        <SectionWrap>
          <Block
            kind={{
              tsx: `<div className="text-[#f6b70e] font-bold text-[50px] sm:text-fluid lg:text-[16px]">
    Shrinking text
</div>`,
              js: `/** @type {import('tailwindcss').Config} */
  const screenSizes = {
    sm: 320,
    md: 1000,
    lg: 1800,
  };
  
  const fluidFontSize = {
    minValue: 50,
    maxValue: 16,
  };

  const fluidCalc = \`calc($\{fluidFontSize.minValue}px + ($\{fluidFontSize.maxValue} - $\{fluidFontSize.minValue}) * (100vw - $\{screenSizes.sm}px) / ($\{screenSizes.lg} - $\{screenSizes.sm}))\`;
  
  module.exports = {
    content: ["./src/**/*.{html,tsx}"],
    theme: {
      extend: {
        screens: {
          sm: \`$\{screenSizes.sm}px\`,
          md: \`$\{screenSizes.md}px\`,
          lg: \`$\{screenSizes.lg}px\`,
        },
        fontSize: {
          fluid: fluidCalc,
        },
      },
    },
  };
  `,
            }}
            features={[
              "Kõik funktsioonid s.h. calc() tuleb defineerida config failis",
              "Ka funktsioonide sisendväärtused tuleb eeldefineerida config failis",
              "Väärtused laiali mitmes failis",
              "Calc funktsioonide vaatest välja tõstmisega muutub nende mõtteline seos vaatega raskesti hallatavaks",
              "Soodustab <i>kirjuta ja unusta</i> lähenemist s.t. loetavus ja seosed marginaalsed",
            ]}
          >
            <div className="text-[#f6b70e] font-bold text-[50px] sm:text-fluid lg:text-[16px]">
              Shrinking text
            </div>
          </Block>

          <Block
            kind={{
              tsx: `<div className={styles["shrinking-text"]}>Shrinking text</div>`,
              css: `$screen-range-min: 320;
$screen-range-max: 1440;

@function custom-calc($min-value, $max-value, $unit: 'px') {
    @return calc(
          (#{$min-value}#{$unit}) + ($max-value - $min-value) * (100vw - #{$screen-range-min}px) / ($screen-range-max - $screen-range-min)
    );
}

.shrinking-text {
    color: #f6b70e;
    font-weight: bold;
    font-size: 50px;

@media screen and (min-width: 320px) {
    font-size: custom-calc(50, 16);
}

@media screen and (min-width: 1400px) {
    font-size: 16px;
}
            `,
            }}
            features={[
              "Kõik lokaalse tähtusega muutujad ja väärtused ühes kohas",
              "Loogiliselt kergesti jälgitav",
              "Taaskasutatav kood",
            ]}
          >
            <div className={styles["shrinking-text"]}>Shrinking text</div>
          </Block>
        </SectionWrap>
      </View>
    </>
  );
};

interface SectionWrapProps {
  children?: ReactNode;
  className?: string;
}

const SectionWrap: FC<SectionWrapProps> = ({ children, className }) => (
  <div className={classNames(styles["section-wrap"], className)}>
    {children}
  </div>
);

interface BlockProps {
  children?: ReactNode;
  kind?: { tsx?: string; css?: string; js?: string };
  features?: string[];
}

const Block: FC<BlockProps> = ({ children, kind, features }) => {
  return (
    <div className={styles.block}>
      {children && <div className={styles["child-wrap"]}>{children}</div>}

      {kind?.tsx && (
        <div className={styles.tsx}>
          <CodeBlock
            language="tsx"
            text={kind.tsx}
            showLineNumbers={false}
            theme={monoBlue}
          />
        </div>
      )}

      {kind?.js && (
        <div>
          <CodeBlock
            language="js"
            text={kind.js}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>
      )}

      {kind?.css && (
        <div className={styles.css}>
          <CodeBlock
            language="css"
            text={kind.css}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>
      )}

      {features && (
        <List>
          {features?.map((feature, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: feature }} />
          ))}
        </List>
      )}
    </div>
  );
};

const Arrow: FC<{
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}> = ({ width, height, fill, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 195.36 139.85"
    >
      <polygon
        points="191.83 66.39 184.64 59.2 128.98 3.54 125.44 0 121.9 3.54 114.71 10.73 111.18 14.26 114.71 17.8 157.17 60.26 5 60.26 0 60.26 0 65.26 0 75.43 0 80.43 5 80.43 156.34 80.43 114.71 122.05 111.18 125.59 114.71 129.12 121.9 136.31 125.44 139.85 128.98 136.31 184.64 80.65 191.83 73.46 195.36 69.92 191.83 66.39"
        fill={fill}
      />
    </svg>
  );
};

const List: FC<{ children: ReactNode }> = ({ children }) => (
  <ul className={styles.list}>{children}</ul>
);

const View: FC<{ children?: ReactNode }> = ({ children }) => (
  <div className={styles.view}>{children}</div>
);
