import classNames from "classnames";
import React, { useState } from "react";

import { Menu } from "./components/menu/Menu";
import { Main } from "./Main";
import { WindowDimensions } from "./components/window-dimensions/WindowDimensions";

import styles from "./app.module.scss";

export default function App() {
  // get url
  const [location, setLocation] = useState<string>();

  return (
    <div className={styles.app}>
      <WindowDimensions>
        {location !== "/tailwind-test" && (
          <Menu
            className={classNames({ [styles.menu]: location === "/" })}
            openClassName={styles["menu--open"]}
          />
        )}
        <Main url={setLocation} />
      </WindowDimensions>
    </div>
  );
}
